import * as React from 'react';
import {useState,useEffect,useRef} from 'react';

import Input from '@mui/material/Input';
import { Box, Button, ButtonGroup, Container, Divider, Fade, TextField, Typography } from '@mui/material';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SendIcon from '@mui/icons-material/Send';
import LaunchIcon from '@mui/icons-material/Launch';
import { alpha } from '@mui/system';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function BottomBar(props) {

    const {apiUrl, chatbot, chatBubbles, setChatbubbles,cl_key, isMsging, setIsMsging, keywords}  = props;
    const [message, setMessage] = useState("");

    //추천질문 Fade 상태
    const [showRecommendations, setShowRecommendations] = useState(false);

    const recommendationsRef = useRef(null);

    // 배경 클릭 시 창 닫기
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (recommendationsRef.current && !recommendationsRef.current.contains(event.target)) {
                setShowRecommendations(false);
            }
        };

        // 클릭 이벤트 리스너 추가
        document.addEventListener('mousedown', handleClickOutside);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //메시지 전송 함수
    function sendMsg(user_input){
        console.log(user_input);

        var userMsgObj = {
            isUser: true,
            message: user_input
        }

        setChatbubbles(prevChatBubbles => [...prevChatBubbles, userMsgObj]);
        setIsMsging(true);

        // axios를 사용하여 요청하고 결과 받기
        axios.post(apiUrl+'/ajax/chat_answer_axios', {
            user_input: user_input,
            cl_key: cl_key,
            c_key: chatbot.c_key
        })
        .then(function (response) {
            console.log(response.data);
            if (response.data.resCd === 200) {
                console.log(response.data.data);
                // botMsgBubble(response.data.bot_msg);
                var botMsgObj = {
                    isUser: false,
                    message: response.data.bot_msg
                }
                if(response.data.simQust) botMsgObj.simQust = response.data.simQust;
                setChatbubbles(prevChatBubbles => [...prevChatBubbles, botMsgObj]);
            } else {
                // var errorMsg = "챗봇 엔진이 과도하게 바쁜 상태입니다. 페이지를 새로고침 후 다시 시도해주세요!";
                console.log(response.data);
                var botMsgObj = {
                    isUser: false,
                    message: response.data.resMsg
                }
                setChatbubbles(prevChatBubbles => [...prevChatBubbles, botMsgObj]);
            }
        })
        .catch(function (error) {
            console.log(error);
            var botMsgObj = {
                isUser: false,
                message: "챗봇 엔진이 응답시간을 초과했습니다. 잠시 후 다시 시도해주세요!"
            }
            setChatbubbles(prevChatBubbles => [...prevChatBubbles, botMsgObj]);
        })
        .finally(function () {
            setIsMsging(false);
        });
    }

  const handleSend = () => {
    sendMsg(message); // TextField의 값을 sendMsg 함수에 전달
    setMessage('');   // 메시지 전송 후 TextField 초기화
  };

    // +버튼 클릭 핸들러
    const handleAddClick = () => {
        setShowRecommendations((prev) => !prev); // Fade 애니메이션 토글
    };

    const setMessageAndSend = (keyword) =>{
        sendMsg(keyword); // TextField의 값을 sendMsg 함수에 전달
        setShowRecommendations(false);
    }

  const c_color = chatbot.c_color;

  return (
    <div>
        <Container sx={{
                    width:'99.5vw',
                    position: 'fixed', // 위치를 고정
                    bottom: 0, // 화면 하단에 배치
                    left: 0, // 왼쪽에 맞춤
                    right: 0, // 오른쪽에 맞춤
                    zIndex: 1000, // 다른 요소들보다 위에 오도록 z-index 설정
                    border:'1px solid #EAE7EC',
                    // maxWidth: '520px',
                    // minWidth: '350px',
                    //margin:'0 auto',
                    display:'flex',
                    padding: '2px',
                    backgroundColor:"#fff"
                }}>
            <Button sx={{ color:c_color }}  onClick={handleAddClick}>
                <LaunchIcon  sx={{
                    fontSize:'30px',
                    rotate : 'revert'
                }}/>
            </Button>
            <TextField type='text' 
                sx={{ 
                    width:'80%',
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: alpha(c_color,0.8), // 포커스 시 레이블 색상
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        '& fieldset': {
                            // borderColor: alpha(c_color,0.8), // 기본 테두리 색상
                        },
                        '&:hover fieldset': {
                            borderColor: alpha(c_color,0.3), // hover 시 테두리 색상
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: alpha(c_color,0.8), // 포커스 시 테두리 색상
                        },
                        
                    },
                 }} 
                onChange={(e) => setMessage(e.target.value)} 
                label="질문하기"
                value={message}
                disabled = {isMsging ? true : false}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !isMsging && message !== '') {
                        handleSend();
                    }
                }}
                />    
            <Button sx={{ color:c_color }} 
                onClick={handleSend}
                disabled = {isMsging || message === '' ? true : false}
                >
                <SendIcon sx={{fontSize:'30px'}}/>
            </Button>
        </Container>
        
        <Fade in={showRecommendations} timeout={{ enter: 500, exit: 500 }} // Fade In: 1초, Fade Out: 0.5초
        >
            <Box 
            ref={recommendationsRef}
            sx={{ 
                position:'fixed',
                bottom : '65px',
                left: '50%', // 화면 중앙으로 이동
                transform: 'translateX(-50%)', // 중앙 정렬을 위해 -50% 이동
                width:'95%',
                backgroundColor : alpha(c_color,0.7),
                margin : '0 auto',
                border:1, borderColor: c_color,
                overflow:'hidden',
                borderRadius:'25px',
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)' // 뿌연 그림자 추가
            }}>
                <Typography variant="h6" sx={{ backgroundColor:c_color, color:"#fff", padding:"5px",fontSize:'15px',textAlign:'center' }}>
                    <TouchAppIcon/>
                    추천 질문을 눌러주세요!
                </Typography>
                <Divider/>
                <Container sx={{ 
                    width:'90%',
                    overflowX : 'scroll', 
                    padding:'5px'
                }}>
                    <Box variant="outlined" aria-label="Basic button group" sx={{ 
                        display: 'flex',
                        gap: 1, // 버튼 간 간격 설정
                        backgroundColor:'transparent',
                        padding:'5px 8px 15px 8px',
                    }}>
                        {keywords.map((keyword,index)=>{
                            return (
                                <Button key={index} sx={{ 
                                    backgroundColor:"#fff",
                                    color : c_color ,
                                    minWidth: 'fit-content', // 최소 너비를 텍스트에 맞게 설정
                                    whiteSpace: 'nowrap', // 줄바꿈을 막기 위해 설정
                                }}
                                onClick={()=>setMessageAndSend(keyword)}

                                >{keyword}</Button>
                            )
                        })}
                    </Box>
                </Container>
            </Box>
        </Fade>
    </div>
  );
}