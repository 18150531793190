import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function CloseDialog(props) {

  const {open , onClose, onOpen,setIsClosed } = props;


  //챗봇 종료하기
  const chatClose= ()=>{
    onClose();
    setIsClosed(true);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"알림"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            대화를 종료하고 창을 닫을까요?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  color="primary" onClick={onClose}>취소</Button>
          <Button  color="error" onClick={chatClose} autoFocus>
            종료
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}