import { Backdrop, CircularProgress, Container, Typography } from "@mui/material";


export default function LoadingBackdrop(props) {

    const {isLoaded} = props;

    return (
        <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={!isLoaded}
        // onClick={handleClose}
        >
          <Container sx={{ display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',gap:2 }}>
            <CircularProgress color="inherit"/>
            <Typography>챗봇을 불러오고 있습니다!</Typography>
          </Container>
        </Backdrop>
    )
}