import * as React from 'react';
import { AppBar, Box, FormControlLabel, Radio, RadioGroup, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover, List, ListItem, ListItemText, Divider } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Card} from '@mui/material';
import axios from 'axios';
import CloseDialog from '../dialogs/CloseDialog';
import { keyframes } from '@mui/system';
import HelpDialog from '../dialogs/HelpDialog';
import ShareDialog from '../dialogs/ShareDialog';

export default function Header(props) {

    const { apiUrl,chatbot, cl_key, setIsClosed } = props;

    const c_color = chatbot.c_color;
    const c_name = chatbot.c_name;
    const c_subtitle = chatbot.c_subtitle;
    const c_key = chatbot.c_key;

    const [isReported,setIsReported] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [helpDialOpen, setHelpDialOpen] = useState(false);
    const [shareDialOpen, setShareDialOpen] = useState(false);

    //도움말 열기 닫기
    const handleHelpDialOpen = ()=>{
        setHelpDialOpen(true);
        handleClose();
    }

    const handleHelpDialClose = () => {
        setHelpDialOpen(false);
    }

    //모달 열기 / 닫기
    const handleModalOpen = () => {
        setModalOpen(true);
        handleClose();
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    //공유하기 다이얼로그 열기 / 닫기
    const handleShareDialOpen =() =>{
        setShareDialOpen(true);
        handleClose();
    }

    const handleShareDialClose =() =>{
        setShareDialOpen(false);
    }


    const handleReportSubmit = (selectedReason) => {

        if(!isReported){
            const post = {
                c_key : c_key,
                cl_key : cl_key,
                r_reason :selectedReason
            }

            axios.post(apiUrl+"/ajax/reportChatbot_axios",post)
                .then((response)=>{
                    if (response.data.resCd === 200) {
                        console.log(response.data.data);
                        setIsReported(true);
                        handleSnackClick("error","신고처리 되었습니다!");
                    } else {
                        console.log(response.data);
                        handleSnackClick(response.data.resMsg);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    handleModalClose();
                });
        }else{
            handleSnackClick("warning","이미 신고처리 되었어요!");
        }
        handleModalClose();
    };
    
    //리스트 모달 관련 상태값
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // 부모에서 Snackbar 열기/닫기 상태를 관리
    const [snackState, setSnackState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });

    const [snackMessage, setSnackMessage] = useState("");

    // snackClick을 통해 Snackbar를 열기 위한 함수
    const handleSnackClick = (variant,message) => {
        setSnackMessage(message);
        setSnackState({ ...snackState, open: true });
        setTimeout(function(){
            handleSnackClose();
        },2000)
    };

    // snackClose를 통해 Snackbar를 닫기 위한 함수
    const handleSnackClose = () => {
        setSnackMessage("");
        setSnackState({ ...snackState, open: false });
    };
    
    const [closeDialOpen, setCloseDialOpen] = React.useState(false);

    const handleDialOpen = () => {
        setCloseDialOpen(true);
    };
  
    const handleDialClose = () => {
        setCloseDialOpen(false);
    };

    

    // Ticker 애니메이션 정의
    const tickerAnimation = keyframes`
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
    `;

    return (
        <div>
            <AppBar color='white' sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                justifyContent: 'space-between', 
                                p: 1, 
                                color: c_color, 
                                backgroundColor: "#fff" 
                }}
            >
                <Button sx={{color:'#6c757d', minWidth: '36px', padding: '4px'}}
                    onClick={handleDialOpen}
                > <KeyboardArrowLeftIcon/> </Button>

                <Container sx={{ 
                        textAlign:'left',
                        display: 'flex', 
                        flexDirection: 'column',
                        position: 'relative', // 위치 기준을 relative로 설정
                        overflow: 'hidden',   // 컨테이너 내에서만 텍스트 스크롤
                    }}>
                    <Typography sx={{ fontWeight:'bold',margin:'0'}}>{c_name}</Typography>
                    <Typography sx={{ 
                                    position: 'relative',   // 컨테이너에 고정
                                    top : "0px",
                                    margin: '0',
                                    color: '#6c757d',
                                    fontSize: '13px',
                                    whiteSpace: 'nowrap',  // 한 줄로 유지
                                    overflow: 'hidden',    // 넘치는 부분 숨기기
                                    display: 'inline-block',
                                    animation: `${tickerAnimation} 10s linear infinite`, // 10초에 한 번씩 반복
                                }}
                                    >{c_subtitle}</Typography>
                </Container>

                <Button sx={{color:'#6c757d', minWidth: '36px', padding: '4px'}} onClick={handleClick}> <MoreVertIcon/> </Button>

                {/* Popover for the List */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List sx={{ width: '200px' }}>
                        <ListItem onClick={handleModalOpen}> {/* 'button' 속성만 사용 */}
                            <ListItemText primary="신고하기" />
                        </ListItem>
                        <Divider />
                        <ListItem onClick={handleShareDialOpen}> {/* 'button' 속성만 사용 */}
                            <ListItemText primary="공유하기" />
                        </ListItem>
                        <Divider />
                        <ListItem onClick={handleHelpDialOpen}> {/* 'button' 속성만 사용 */}
                            <ListItemText primary="도움말" />
                        </ListItem>
                    </List>
                </Popover>
            </AppBar>

            {/* 신고하기 모달 */}
            <ReportModal 
                modalOpen={modalOpen} 
                onClose={handleModalClose} 
                onSubmit={handleReportSubmit}
            />

            <Snackbar
                open={snackState.open}
                vertical={snackState.vertical}
                horizontal={snackState.horizontal}
                onClose={handleSnackClose}
                message={snackMessage} // 메시지를 'message' prop으로 전달
                // autoHideDuration={2000} // 2초 후 자동으로 닫히도록 설정
                />

            {/* 닫기 물어보는 다이얼로그 */}
            <CloseDialog 
                open={closeDialOpen}
                onClose={handleDialClose}
                onOpen={handleDialOpen}
                setIsClosed = {setIsClosed}
            />

            {/* 도움말 다이얼로드 */}
            <HelpDialog
                open={helpDialOpen}
                onClose={handleHelpDialClose}
                onOpen={handleHelpDialOpen}
            />

            <ShareDialog
                open={shareDialOpen}
                onClose={handleShareDialClose}
                onOpen={handleShareDialOpen}
            />
        </div>
    );
}

function ReportModal({ modalOpen, onClose, onSubmit }) {
    const [selectedReason, setSelectedReason] = useState("기타 문제");

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(selectedReason);
        onClose();
    };

    return (
        <Dialog
            open={modalOpen}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="report-modal-title"
            sx={{}}
        >
            <DialogTitle id="report-modal-title" sx={{  m: 0, p: 1, textAlign:'center'  }}>
                신고하기
            </DialogTitle>

            <DialogContent dividers sx={{ p: 0.2 }}>
                <Container maxWidth="sm" sx={{ p:0 }}>
                    <Box sx={{ p: 2 }}>
                        <Typography sx={{ mb: 1, fontSize: '12px' }}>
                            문제가 되는 이유를 선택해주세요.
                        </Typography>
                        <RadioGroup
                            value={selectedReason}
                            onChange={handleReasonChange}
                            sx={{ }}
                        >
                            {["폭력적 또는 선정적인 텍스트나 이미지", "불쾌한 텍스트 또는 이미지", "인종 또는 장애에 대한 차별, 모욕", "과도한 홍보성 또는 스팸", "기타 문제"].map((reason, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={reason}
                                    control={<Radio />} // Radio 컴포넌트는 여기서 직접 사용됩니다.
                                    label={<Typography fontSize="13px">{reason}</Typography>}
                                    sx={{ py: 1, alignItems: 'center' }}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                </Container>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between', p: 1.5 }}>
                <Button
                    color="dark"
                    size="small"
                    onClick={onClose}
                    sx={{ textTransform: 'none' }}
                >
                    취소
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleSubmit}
                    sx={{ textTransform: 'none' }}
                >
                    신고
                </Button>
            </DialogActions>
        </Dialog>
    );
}
