import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useState,useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

import { Backdrop, BottomNavigation, Box, CircularProgress, Container, LinearProgress, Paper, Typography } from '@mui/material';

import BottomBar from './components/bottomMenu';

import Header from './include/Header';

import ChatScreen from './components/ChatScreen';
import Noticebar from './components/Noticebar';
import { ChatBubble } from '@mui/icons-material';
import BasicSpeedDial from './components/BasicSpeedDial';
import LoadingBackdrop from './backdrops/LoadingBackdrop';
import CloseBackdrop from './backdrops/CloseBackdrop';


const theme = createTheme({
  typography: {
    fontFamily: 'Pretendard-Regular, Arial, sans-serif',
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '520px',  // 앱의 최대 가로 길이를 520px로 제한
          margin: '0 auto',   // 화면 중앙 배치
        },
      },
    },
  }
});

function App() {

  //const apiUrl = 'http://localhost:8080';
  const apiUrl = 'https://simchat.aimor.site';
  const urlParams = new URLSearchParams(window.location.search);
  const c_key = urlParams.get('c_key');
  const [cl_key,setCl_key] = useState();
  const [cs_questions,setCs_questions] = useState([]);
  const [keywords,setKeywords] = useState([]);
  const [notices,setNotices] = useState([]);
  const [chatbot,setChatbot] = useState();
  const [c_color,setC_color] = useState();
  const [isActive,setIsActive] = useState();

  //메시지 응답 대기중
  const [isMsging,setIsMsging] = useState(false);

  //페이지 첫 로딩일 경우의 상태
  const [isLoaded,setIsLoaded] = useState(false);

  //챗봇을 종료하였는가?
  const [isClosed,setIsClosed] = useState(false)

  //채팅 버블 
  const [chatBubbles,setChatbubbles] = useState([]);

  const postData = {c_key:c_key};

  // title과 favicon을 동적으로 설정하는 함수
  const changeTitleAndFavicon = (c_title,href) => {
    document.title = c_title; // 페이지 타이틀 설정

    // favicon 변경
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = href; // 새 아이콘 URL로 변경
    }
  };


  const chatbot_res = async() => await axios.post(apiUrl+"/ajax/getMsgDatas",  postData)
      .then((response)=>{
        //console.log(response);
        const data = response.data;
        if(data.resCd == 200){
          if(!isLoaded){
            setIsLoaded(true); //Loading 완료
            setCl_key(data.cl_key);
            setCs_questions(data.cs_questions.split(','));
            setNotices(data.notices);
            setChatbot(data.chatbot);
          }
        }else{
          alert(data.resMsg);
          setIsClosed(true); //정상적이지 않으므로 종료
          window.history.back();
        }
      }).catch(()=>{

      });
  
  //최초 실행
  useEffect(()=>{

    chatbot_res();

  },[])


  //로딩이 된 상태를 감지해서 실행할 useEffect
  useEffect(() => {
    if (isLoaded) {
      setChatbot((prevChatbot) => ({
        ...prevChatbot, // 이전 상태를 복사
        c_icon: apiUrl + '/' + prevChatbot.c_icon, // c_icon만 수정
      }));
      setIsActive(chatbot.active);
      setKeywords(chatbot.keywords.split(','));
    }
  }, [isLoaded]); // chatbot 의존성 추가


  useEffect(()=>{
    if(chatbot){
      //웰컴 메시지 넣기
      setChatbubbles(
        [ 
          {
            "isUser":false,
            "message":chatbot.welcome_msg
          },
        ]
      );
      
      //c_color 변경
      setC_color(chatbot.c_color);

      // 컴포넌트 마운트 시 변경
      changeTitleAndFavicon("["+chatbot.c_name+"]의 챗봇", chatbot.c_icon);
      
    }

  },[chatbot])

  return (

    <ThemeProvider theme={theme}>
          <div className="App" sx={{ backgroundColor:"#fff" }}  >
              {/* Header(AppBar) */}
              {chatbot && <Header apiUrl={apiUrl} chatbot={chatbot} notices={notices} cl_key={cl_key} setIsClosed={setIsClosed}/>}

              {/* Sized Box */}
              <div className="sizedBox30" style={{height:"65px"}}/>
              {/* NoticeBar */}
              { (chatbot && isActive === 'Y') &&  
                <Noticebar notices={notices} c_color={c_color}/>}

              { (chatbot && isActive === 'Y') &&  
                <Container sx={{ display:"flex", justifyContent:"center" }}>
                  <Paper elevation={3}
                    sx={{
                    p: 1.5,
                    marginTop:'5px',
                    maxWidth: '80%',
                    backgroundColor: c_color,
                    opacity : "0.5",
                    color: "#fff",
                    borderRadius: 2}}
                  >
                    <Typography sx={{ fontSize:'12px', color:"#f8f8f8" }}>{chatbot.c_name}의 대화를 시작합니다!</Typography> 
                  </Paper>
                </Container>}
              { (chatbot && isActive === 'Y') &&  
                <ChatScreen apiUrl={apiUrl} 
                            chatbot={chatbot} 
                            cl_key={cl_key} 
                            chatBubbles={chatBubbles} 
                            setChatbubbles={setChatbubbles} 
                            isMsging = {isMsging} 
                            setIsMsging={setIsMsging}
                            />}
              { (chatbot && isActive === 'N') &&  
                <Container sx={{ display:"flex", justifyContent:"center" }}>
                    <Paper elevation={3}
                      sx={{
                      p: 1.5,
                      marginTop:'5px',
                      maxWidth: '80%',
                      backgroundColor: c_color,
                      opacity : "0.5",
                      color: "#fff",
                      borderRadius: 2}}
                    >
                      <Typography sx={{ fontSize:'12px' }}>해당 챗봇은 관리자에 의해 사용이 중지된 챗봇입니다.</Typography> 
                    </Paper>
                </Container>
              }
              {/* CurrentPage */}
                  {/* {currentMenu == 'feed'&&  <FeedPage /> }
                  {currentMenu == 'search'&&  <SearchPage /> }
                  {currentMenu == 'favorite'&&  <FavoritePage currentMenu={currentMenu} setCurrentmenu={setCurrentmenu} /> }
                  {currentMenu == 'myinfo'&&  <MyInfo /> } */}
              
              {/* Sized Box */}
              <Container  sx={{display:isMsging ? 'flex' : 'none', flexDirection:'column',justifyContent:'center',alignItems:'center', gap:2}}>
                  <Typography sx={{ fontSize:'12px',fontWeight:"bold",color:"#989898" }}>대답을 생각하고 있어요!</Typography>
                  <CircularProgress color="info" />
              </Container>
              {/* {chatbot && <BasicSpeedDial cs_questions={cs_questions}/>} */}
              <div className="sizedBox30" style={{height:"60px"}}/>                
              {/* BottomBar */}
              
              { (chatbot && isActive === 'Y' && !isClosed) &&  
              <BottomBar 
                            apiUrl={apiUrl} 
                            chatbot={chatbot} 
                            cl_key={cl_key} 
                            chatBubbles={chatBubbles} 
                            setChatbubbles={setChatbubbles} 
                            isMsging = {isMsging}
                            setIsMsging={setIsMsging}
                            keywords={keywords}
                            />}
          </div>
          
          {/* 로딩용 백드롭 */}
          <LoadingBackdrop isLoaded={isLoaded}/>

          {/* 종료용 백드롭 */}
          <CloseBackdrop isClosed={isClosed}/>

        </ThemeProvider>    
  );
}

export default App;
