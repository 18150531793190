import { Backdrop, CircularProgress, Container, Typography } from "@mui/material";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { keyframes } from '@mui/system';


export default function CloseBackdrop(props) {

    const {isClosed} = props;

    // 애니메이션 정의
    const pulseAnimation = keyframes` 
    0%, 50%  100% {
        transform: scale(1.1) rotate(0deg);
    }
    25% {
        transform: scale(1.0) rotate(10deg);
    }
    75%{
        transform: scale(1.0) rotate(-10deg);
    }
    `;

    return (
        <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isClosed}
        // onClick={handleClose}
        >
          <Container sx={{ display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',gap:2 }}>
            {/* <CircularProgress color="inherit"/> */}
            <DisabledByDefaultIcon sx={{ 
                fontSize:"150px",fontWeight:"bold",color:"#FD5361", 
                animation: `${pulseAnimation} 2s infinite ease-in-out` // 애니메이션 적용
                }}></DisabledByDefaultIcon>
            <Typography>대화가 종료되었습니다</Typography>
          </Container>
        </Backdrop>
    )
}