import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Chip, Container, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ShareDialog(props) {

  const {open, onClose, onOpen } = props;

  //클립보드에 주소를 복사하기
  const copyPath=()=>{
    const currentUrl = window.location.href; // 현재 URL 가져오기
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        alert("주소가 클립보드에 복사되었습니다!");
      })
      .catch((error) => {
        console.error("클립보드 복사 실패:", error);
      });
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 1, textAlign:'center' }} id="customized-dialog-title">
          공유하기
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            <Container sx={{ display:'flex', alignItems:'center',gap:1, justifyContent:'center'}}>
                {/* <CheckIcon sx={{ fontWeight:"bold", fontSize:'20px',padding:'0px 5px' }}/>  */}
                <Typography variant='span' sx={{ fontSize:'13px',margin:'10px 5px' }}>
                  아래 주소를 클릭하면
                  해당 챗봇의 주소를 복사합니다!
                </Typography>
            </Container>
            
            <Divider sx={{maxWidth:'80%', margin:'10px auto'}}/>

            <Chip     size='large' color="primary" variant="outlined"
                      sx={{ display:'flex', alignItems:'center',gap:1, justifyContent:'center' }}  
                      label = {window.location.href}
                      onClick={copyPath}>
                      
                {/* <CheckIcon sx={{ fontWeight:"bold", fontSize:'20px',padding:'0px 5px' }}/>  */}
                {/* <Typography variant='span' sx={{ fontSize:'13px',margin:'' }}>
                  <span style={{fontWeight:'bold', color:'#6574CD'}}>
                    {window.location.href}
                  </span>
                </Typography> */}
            </Chip>

        </DialogContent>
        <DialogActions>
          <Button  color='info' autoFocus onClick={onClose}>
            확인했어요!
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}